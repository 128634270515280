<template>
  <div class="column-container md-scrollbar" id="container">
    <v-row>
      <v-col cols="9"> </v-col>
      <v-col cols="3">
        <md-field>
          <label for="package-number">Package</label>
          <md-select
            name="package-number"
            id="package-number"
            v-model="packageNumber"
            @md-selected="packageFilter"
            :disabled="!loaded"
          >
            <md-option
              v-for="(item, index) in packages"
              :key="index"
              :value="item.value"
            >{{ item.display_name }}
            </md-option>
          </md-select>
        </md-field>
      </v-col>
    </v-row>
    <v-row v-if="loaded && !collections.length">
      <v-col cols="12">
        <span>
          <v-icon slot="icon" size="36">
            mdi-emoticon-sad-outline
          </v-icon>
          {{ this.message }}
        </span>
      </v-col>
    </v-row>
    <div class="spinner-box">
      <md-progress-spinner
        v-if="!loaded || dragProcessing"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div v-if="loaded && collections.length" class="card-scene">
      <Container
        orientation="horizontal"
        drag-handle-selector=".column-drag-handle"
        :drop-placeholder="upperDropPlaceholderOptions"
        :auto-scroll-enabled="true"
      >
        <Draggable
          v-for="column in scene.children.children"
          :key="column.serviceId"
        >
          <ColumnList
            :column="column"
            :package="packageNumber"
            @show-parallel-service="showParallelService"
            @card-dropped="cardDropped"
            @drop-error="cardDropError"
            @drop-success="afterCardDrop"
            @reload-dashboard="prepareDashboard"
          />
        </Draggable>
      </Container>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import auth from "../../auth";
import {Container, Draggable} from "vue-smooth-dnd";
import restAdapter from "../../restAdapter";
import notification from "../../notification";
import ColumnList from "../../components/ColumnList.vue"
import store from "@/store";



export default {
  name: "InquiryManagementDashboard",
  components: {Container, Draggable, ColumnList},
  data: () => ({
    dragProcessing: false,
    filterAll: 555,
    packageNumber: 555,
    isDropInSameColumn: false,
    packages: [],
    currentUserRole: null,
    userId: null,
    message: "",
    inquiries: [],
    collections: [],
    columns: [],
    dashBoardSubServices: [],
    active: false,
    collectionId: null,
    assignedSuppliers: [],
    deadline: null,
    services: [],
    loaded: false,
    scene: {
      type: "container",
      props: {
        orientation: "horizontal",
      },
      children: {},
    },
    sceneHistory: null,
    upperDropPlaceholderOptions: {
      className: "cards-drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    dropPlaceholderOptions: {
      className: "drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },

  }),

  created () {
    window.addEventListener('scroll', this.handleScroll);
  },

  mounted() {
    this.userId = auth.user.id;
    this.currentUserRole = auth.user.userType;
    document.getElementById('container').addEventListener('scroll', this.handleScroll);
    this.getPackages()
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },


  methods: {
    cardDropped(data){
      const addedMain = this.scene.children.children.find(col => col.serviceId === data.addedColumn).mainService;
      this.sceneHistory = this.scene;
      this.isDropInSameColumn = false;
      if(this.hasParallelInAddedColumn(data)){
        notification.error("Already has a parallel service there");
        return;
      }
      this.scene = {
        ...this.scene,
        children: {
          ...this.scene.children,
          children: this.scene.children.children.map(column => {
            if(column.id === data.payload.current_sub_services.sub_service_order && column.mainService === data.payload.current_sub_services.parent_service){
              if(this.scene.children.children.find(column => column.serviceId === data.addedColumn).children.find(card => card.id === data.payload.id)){
                this.isDropInSameColumn = true;
                let collections = column.children.filter(card=> card.id !== data.payload.id);
                collections = [...collections.slice(0, data.index), data.payload, ...collections.slice(data.index)]
                return {...column, children: [...collections]}
              }
              return {...column, children: column.children.filter(card=> card.id !== data.payload.id)}
            }
            if (column.serviceId === data.addedColumn) {
              return {...column, children: [...column.children.slice(0, data.index), data.payload, ...column.children.slice(data.index)]}
            }
            if(addedMain !== data.payload.current_sub_services.parent_service){
              return {...column, children: column.children.filter(card=> card.id !== data.payload.id)}
            }
            return {...column}
          })
        }
      }
      this.updateCollectionStatus(data.payload, data.addedColumn, data.removedColumn);
    },
    cardDropError(){
      this.scene = this.sceneHistory;
    },
    afterCardDrop(data){
      if(!data.collection_data){
        this.scene = {
          ...this.scene,
          children: {
            ...this.scene.children,
            children: this.scene.children.children.map(column => {
              if(data.droppedCardData.order_number === column.id &&
                column.mainService === data.droppedCardData.parent_service){
                  return {
                    ...column,
                    children: column.children.map(child => {
                      if(child.id === data.droppedCardData.collection_id){
                        return {...child, current_sub_services: {
                          ...child.current_sub_services,
                            collection_id: data.droppedCardData.collection_id,
                            collection_sub_service_id: data.droppedCardData.id,
                            parent_service: data.droppedCardData.parent_service,
                            sub_service_id: data.droppedCardData.sub_service_id,
                            sub_service_order: data.droppedCardData.order_number
                        }}
                      }
                      return {...child}
                    })
                  }
              }
              return {...column}
            })
          }
        }
        if(this.isDropInSameColumn){
          return null;
        }
        return "no-parallel";
      }

      this.scene = {
        ...this.scene,
        children: {
          ...this.scene.children,
          children: this.scene.children.children.map(column => {
            let isFounded = false;
            for(let i = 0 ; i < data.collection_data[0].current_sub_services.length; i++){
              if (data.collection_data[0].current_sub_services[i].sub_service_order === column.id &&
                column.mainService === data.collection_data[0].current_sub_services[i].parent_service) {
                isFounded = true;
                let found = false;
                let toAdd = {
                  ...column, children: column.children.map(child => {
                    if(child.id === data.collection_data[0].id){
                      found = true;
                      return {
                        ...child,
                        current_sub_services: {...data.collection_data[0].current_sub_services[i]},
                        hasParallelService: data.collection_data[0].current_sub_services.length > 1,
                      }
                    }
                    return {...child}
                  })
                }
                if(!found){
                  toAdd = {
                    ...column, children: [...column.children, {...data.collection_data[0], hasParallelService: data.collection_data[0].current_sub_services.length > 1, current_sub_services: {...data.collection_data[0].current_sub_services[i]}}]
                  }
                }

                return {...toAdd}
              }
            }
            if(!isFounded){
              return {...column}
            }
          })
        }
      }
      return "has-parallel";
    },

    updateCollectionStatus(collection, addedIndex, removedIndex) {
      const collectionId = collection.id;
      const payload = {
        removedIndex: removedIndex,
        addedIndex: addedIndex
      };
      restAdapter.post(
        "/api/dashboard/collections/" + collectionId + "/updateStatus",
        payload
      ).then(response => {
        const anyReturn = this.afterCardDrop(response.data);
        if(anyReturn){
          store.commit('SET_RELOAD_DROP_CARD', `${collectionId}-${addedIndex}`);
          notification.success(response.data.message);
        } else {
          store.commit('SET_RELOAD_DROP_CARD', `${collectionId}-${addedIndex}-currentColumn`)
        }
      }).catch(error => {
        this.cardDropError();
        notification.error(error.response.data.error);
      });
    },

    getDashboardColumns() {
      const url = "/api/dashboard/services/" + this.packageNumber;
      restAdapter.get(url).then((response) => {
        this.dashBoardSubServices = response.data.subServices;
        let icon = null;
        let color = null;
        this.dashBoardSubServices.forEach((subService, key) => {
          const parentId = subService.parent_service;
          switch (parentId) {
            case 1:
              color = "#0079bf";
              icon = "mdi-pencil";
              break;
            case 2:
              color = "#519839";
              icon = "mdi-file-document-edit-outline";
              break;
            case 3:
              color = "red";
              icon = "mdi-pencil";
              break;
            case 4:
              color = "#ff9f1a";
              icon = "mdi-hanger";
              break;
            case 5:
              color = "#f2d600";
              icon = "mdi-receipt";
              break;
            case 6:
              color = "#00c2e0";
              icon = "mdi-truck-fast";
              break;
            case 7:
              color = "#89609e";
              icon = "mdi-calendar-start";
              break;
            case 8:
              color = "#51e898";
              icon = "mdi-trophy-variant";
              break;
          }
          const column = {
            name: subService.name,
            id: subService.order,
            serviceId: subService.id,
            mainService: subService.parent_service,
            color: color,
          };
          this.columns.push(column);
        });
        this.prepareDashboard();
      });
    },

    prepareDashboard() {
      const url = "/api/dashboard/collections/" + this.packageNumber;
      restAdapter.get(url).then((response) => {
        this.collections = response.data.collections;
        this.fillColumns(this.collections);
        this.loaded = true;
        this.dragProcessing = false;
        this.message = "No collections found."
      }).catch((error) => {
        this.loaded = true;
        this.dragProcessing = false;
        this.message = "Something went very wrong. Please try again"
      });
    },

    fillColumns(collections) {
      const result = {
        children: [],
        type: "container",
        props: {
          orientation: "horizontal",
        },
      };

      this.columns.forEach((column, key) => {
        const newColumn = {
          id: column.id,
          name: column.name,
          color: column.color,
          serviceId: column.serviceId,
          mainService: column.mainService,
          children: [],
          props: {
            orientation: "vertical",
            className: "card-container",
          },
        };

        const columnData = [];

        collections.forEach((collection) => {
          collection.current_sub_services.forEach(sub => {
            if (
              sub.sub_service_order === column.id &&
              column.mainService === sub.parent_service
            ) {
              const toAdd = {...collection, current_sub_services: {...sub}, hasParallelService: collection.current_sub_services.length > 1}
              columnData.push(toAdd);
            }
          })
        });

        newColumn.children = columnData;
        result.children.push(newColumn);
      });
      this.scene.children = result;
    },

    showParallelService(data){
      const collectionId = data.cardId;
      const parallelColumn = this.getParallelService(data);
      document.getElementById(`id-${parallelColumn[0].serviceId}-${collectionId}`).scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
      document.getElementById(`id-${parallelColumn[0].serviceId}-${collectionId}`).style.backgroundColor =
        "rgba(0, 0, 0, 0.3)";
      setTimeout(() => {
        document.getElementById(
          `id-${parallelColumn[0].serviceId}-${collectionId}`
        ).style.backgroundColor = null;
      }, 4000);
    },
    hasParallelInAddedColumn(data){
      let hasParallelAlready = false;
      const addedParentService = this.scene.children.children.find(column => column.serviceId === data.addedColumn)
      if(addedParentService.mainService === data.payload.current_sub_services.parent_service){
        return false;
      }
      const subServicesList = this.scene.children.children.filter(column => column.mainService === addedParentService.mainService);
      for(let i = 0; i < subServicesList.length; i++){
        const haveOne = subServicesList[i].children.find(card => card.id === data.payload.id);
        if(haveOne){
          hasParallelAlready = true;
        }
      }
      return hasParallelAlready;
    },

    getParallelService(data){
      const collectionId = data.cardId;
      const serviceId = data.serviceId;
      const allOtherColumns = this.scene.children.children.filter(column => {
        return column.serviceId !== serviceId;
      })
      const parallelColumn = allOtherColumns.filter(column => {
        if(column.children.find(card => card.id === collectionId)){
          return true;
        }
        return false;
      })

      return parallelColumn;
    },

    async packageFilter() {
      this.loaded = false;
      this.columns = [];
      await this.getDashboardColumns();
    },

    getPackages() {
      restAdapter.get(`/api/inquiries_packages`).then((response) => {
        response.data.packages.forEach((inquiryPackage) => {
          if (inquiryPackage.package_name == "Low") {
            inquiryPackage.display_name = "High-speed production";
            inquiryPackage.value = 3;
          } else if (inquiryPackage.package_name == "Medium") {
            inquiryPackage.display_name = "Medium lead time production";
            inquiryPackage.value = 2;
          } else if (inquiryPackage.package_name == "High") {
            inquiryPackage.display_name = "Longer lead time production";
            inquiryPackage.value = 1;
          }
          this.packages.push(inquiryPackage);
        });
        this.packages.push({ display_name: "All", value: this.filterAll });
        this.packages.reverse();
        this.getDashboardColumns();
      });
    },

    handleScroll () {
      store.commit('SET_SCROLL_TOGGLE', !this.$store.state.toggleScroll);
    },
  },
};
</script>

<style scoped>
.draggable-item {
  flex-direction: row !important;
  justify-content: space-between;
  text-align: center;
}
.card-scene {
  padding: 20px;
}
.column-container {
  overflow: auto;
  width: 100%;
}
.md-card {
  margin: 10px;
}
.md-card-media {
  margin-left: 0;
}
.v-card {
  margin: 5px;
  width: 330px;
  background-color: #f3f3f3;
}
.card-ghost {
  transition: transform 0.18s ease;
  transform: rotateZ(5deg);
}
.card-ghost-drop {
  transition: transform 0.18s ease-in-out;
  transform: rotateZ(0deg);
}
.opacity-ghost {
  transition: all 0.18s ease;
  opacity: 0.8;
  background-color: cornflowerblue;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
}
.opacity-ghost-drop {
  opacity: 1;
  background-color: white;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0);
}
.drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px;
}
.cards-drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px 45px 5px 5px;
}
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #abc;
}
.card-image {
  pointer-events: none;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.md-card-actions.md-alignment-space-between {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.expand-actions {
  padding-top: 0 !important;
  width: 240px;
}
.spinner-box {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  position: fixed;
  z-index: 5;
}
.text-start {
  padding-left: 5px;
}
</style>
