<template>
    <v-card>
      <v-toolbar :color="column.color" dark dense flat>
        <v-toolbar-title>
                {{ column.name }}
              </v-toolbar-title>
            </v-toolbar>
            <Container
              :id="`column-${column.id}`"
              class="column"
              @drop="e => onCardDrop(column.serviceId, column.mainService, e)"
              :group-name="column.name"
              drag-class="card-ghost"
              drop-class="card-ghost-drop"
              :get-child-payload="getCardPayload()"
              :drop-placeholder="dropPlaceholderOptions"
              :should-accept-drop="() => true"
            >
              <Draggable  v-for="(card, index) in column.children" :key="card.id">
                <CardView
                  :cardId="`id-${column.serviceId}-${card.id}`"
                  :index="index"
                  :card="card"
                  :column="column"
                  :activeCard="activeCard"
                  @click="activeCard = $event"
                  @show-parallel-service="findParallel"
                  @reload-dashboard="$emit('reload-dashboard')"
                />
              </Draggable>
            </Container>
          </v-card>
</template>

<script>
import CardView from "./Inquiry/CardView.vue";
import {Container, Draggable} from "vue-smooth-dnd";
import store from "@/store";

export default {
  name: "ColumnList",
  components: {Container, Draggable, CardView},
  props: {
    column: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    dropPlaceholderOptions: {
      className: "drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    activeCard: null,
  }),

  mounted(){
    document.getElementById(`column-${this.column.id}`).addEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll () {
      store.commit('SET_SCROLL_TOGGLE', !this.$store.state.toggleScroll);
    },

    findParallel(data){
      this.$emit('show-parallel-service', data)
    },

    onCardDrop(addedColumnId, mainService, dropResult){
      if(dropResult.addedIndex !== null){
        const addedColumn = addedColumnId;
        const removedColumn = dropResult.payload.current_sub_services.sub_service_id;
        store.commit('SET_LOADING_CARD', `${dropResult.payload.id}-${addedColumn}`);
        this.$emit('card-dropped', {addedColumn: addedColumn, index: dropResult.addedIndex, payload: dropResult.payload, removedColumn: removedColumn});
      }
    },

    getCardPayload() {
      return (index) => {
        const inquiry = this.column.children[index];
        return inquiry;
      };
    },
    notifyToReload(){
      this.$emit("notify-dashboard");
    }
  }
}
</script>
<style>
  .column {
    height: 80vh;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .column::-webkit-scrollbar{
    display: none;
  }
  .card-ghost {
    transition: transform 0.18s ease;
    transform: rotateZ(5deg);
  }
  .card-ghost-drop {
    transition: transform 0.18s ease-in-out;
    transform: rotateZ(0deg);
  }
</style>
