<template>
  <div>
    <md-card :id="cardId" class="inquiry-card">
      <div @click="showChat(card)">
        <md-card-header class="card-header">
          <md-card-media style="margin-left: unset">
            <div class="embed">
              <embed v-if="!imageLoading"
                     class="centered-and-cropped"
                     :src="
                  defaultImageUrl
                "
                     alt="sample image"
              />
            </div>
          </md-card-media>
          <md-card-header-text>
            <v-chip class="ma-2" color="primary" small label>
              <span class="md-body-2">{{ card.collection_number }}</span>
            </v-chip>
            <div v-if="!dataLoaded" class="loadingData"></div>
            <div v-else class="text-start">
              <v-icon color="primary" left> mdi-account
              </v-icon
              >
              {{ brand }}
              <v-spacer></v-spacer>
              <v-icon color="primary" left>
                mdi-calendar-check
              </v-icon
              >
              {{ deadline }}
            </div>
          </md-card-header-text>
        </md-card-header>
      </div>
      <md-card-expand>
        <div v-if="!dataLoaded" class="loadingData" style="height: 45px; margin-bottom: 10px;"></div>
        <div v-else class="actions">
          <md-card-actions md-alignment="space-between">
            <v-chip
              v-if="status === statusData.completed"
              class="ma-2"
              color="green"
              small
              label
              outlined
            >
              <v-icon left>
                mdi-check-bold
              </v-icon>
              <span class="md-body-2">Done</span>
            </v-chip>

            <v-chip
              v-else
              @click="showCalendar = !showCalendar"
              class="ma-2"
              :color="this.calculatedDays.color"
              x-small
              label
              outlined
            >
              <v-icon x-small left>
                mdi-alarm
              </v-icon>
              <span class="md-body-1">{{ this.calculatedDays.message }}</span>
            </v-chip>

            <div class="text-center" v-if="isEndOfMainService && hasParallelService && status !== statusData.completed">
              <div class="my-2">
                <v-tooltip bottom color="success">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="blue"
                      @click="updateMainServiceStatus(card, true)"
                      x-small
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-checkbox-blank-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Mark as completed</span>
                </v-tooltip>
              </div>
            </div>

            <div class="text-center" v-if="isEndOfMainService && hasParallelService && status === statusData.completed">
              <div class="my-2">
                <v-tooltip bottom color="warning">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="blue"
                      @click="updateMainServiceStatus(card, false)"
                      x-small
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-checkbox-marked</v-icon>
                    </v-btn>
                  </template>
                  <span>Mark as incompleted</span>
                </v-tooltip>
              </div>
            </div>

            <div class="text-center" v-if="hasParallelService">
              <div class="my-2">
                <v-tooltip bottom color="blue">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="blue"
                      @click="showParallelService(card, column.mainService)"
                      x-small
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-crosshairs-gps</v-icon>
                    </v-btn>
                  </template>
                  <span>Find the parallel task</span>
                </v-tooltip>
              </div>
            </div>


            <div>
              <md-card-expand-trigger>
                <md-button class="md-icon-button">
                  <v-icon color="blue" @click="getInquiriesAndAssignees(card) ">mdi-chevron-down</v-icon>
                </md-button>
              </md-card-expand-trigger>
            </div>
          </md-card-actions>
        </div>
        <md-card-expand-content>
          <md-card-content style="padding: 5px;">
            <v-list dense>
              <v-divider></v-divider>
              <v-subheader>Styles</v-subheader>
              <v-list-item v-if="!inquiriesLoaded">
                <span>Loading...</span>
              </v-list-item>
              <v-list-item v-else-if="!inquiries.length">
                <span>No styles found</span>
              </v-list-item>
              <div style="max-height: 200px; display: flex; flex-direction: column; overflow: auto;">
                <div @click="openInquiry(inquiry.id)" style="margin: 5px; cursor: pointer" v-for="inquiry in inquiries" :key="inquiry.id">
                  <mini-inquiry :inquiry="inquiry" />
                </div>
              </div>
            </v-list>
            <v-list dense>
              <v-divider></v-divider>
              <v-subheader>Assignees</v-subheader>
              <v-list-item v-if="!assigneesLoaded">
                <span>Loading...</span>
              </v-list-item>
              <v-list-item v-else-if="!assignees.length">
                <span>No accountable person is assigned</span>
              </v-list-item>
              <v-list-item
                v-for="supplier in assignees"
                :key="supplier.id"
              >
                <v-icon>mdi-circle-small</v-icon>
                <span
                  v-text="`${supplier.first_name} ${supplier.last_name} (${supplier.role})`"
                ></span>
              </v-list-item>
            </v-list>
          </md-card-content>
        </md-card-expand-content>
        <div class="expand-actions" v-if="showCalendar">
          <v-date-picker
            full-width
            class="mt-4"
            v-model="changedDeadline"
            @change="changeDeadLine(card)"
            color="green lighten-1"
          ></v-date-picker>
        </div>
      </md-card-expand>
    </md-card>
    <div>
      <v-dialog v-model="active" persistent width="90%">
        <v-card  persistent width="100%">
          <div class="close" @click="closePopUp">
            <popup-close
              style="float: right;"
            />
          </div>
          <md-button
            style="color: #fff;
            background-color: #582963;
            margin-left: 70px;"
            @click="viewCollection"
          >View Collection</md-button>
          <PopupChat
            v-if="refresh"
            :key="card.id"
            :collectionId="card.id"
            :startingRoomId="null"
            :assignedSuppliers="assignedSuppliers"
            :superAdmin="superAdmin"
            :accountManagers="accountManagers"
            :customer="buyer"
            :active="active"
            :activeCard="activeCard"
            @onChangeActive="active = $emit"
            @chat-status="updateChatStatus"
            @chat-id="updateChatId"
            @reload-card="notifiedToReload"
            @reload-dashboard="$emit('reload-dashboard')"
          >
          </PopupChat>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>


<script>

import * as conf from "@/conf";
import PopupClose from "../../assets/icons/PopupClose.vue"
import restAdapter from "@/restAdapter";
import notification from "@/notification";
import PopupChat from "../PopupChat.vue";
import utils from "../../utils";
import store from '@/store';
import routes from '../../router/routes';
import MiniInquiry from "./MiniInquiry";

export default {
  name: 'CardView',
  components: {
    MiniInquiry,
    PopupChat,
    PopupClose,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
    cardId: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    activeCard: {
      type: Number,
      default: null
    },
  },

  data: () => ({
    defaultImageUrl: "",
    imageLoading: true,
    collectionId: null,
    buyer: null,
    superAdmin: null,
    assignedSuppliers: [],
    accountManagers: [],
    inquiries: [],
    sampleImageInquiry: require(`../../assets/shirt.png`),
    sampleImageCollection: require(`../../assets/collection.png`),
    acceptedThumbnailTypes: utils.acceptedThumbnailTypes,
    thumbnail: null,
    statusData: {completed: 3, progress: 2, notStarted: 1},
    display: false,
    loaded: false,
    dataLoaded: false,
    deadline: "",
    changedDeadline: null,
    calculatedDays: {message: "", color: ""},
    status: null,
    brand: null,
    showCalendar: false,
    isEndOfMainService: false,
    hasParallelService: false,
    active: false,
    refresh: false,
    chatId: null,
    inquiriesLoaded: false,
    assigneesLoaded: false,
    assignees: [],

  }),

  watch: {
    toggleScroll:{
      handler: function(){
        this.handleScroll();
      },
    },
    reloadCard:{
      handler: function(){
        this.handleReload();
      },
    },
    loadingCard:{
      handler: function(){
        this.handleLoading();
      },
    }
  },


  computed: {
    toggleScroll() {
      return this.$store.state.toggleScroll;
    },
    elementId() {
      return this.cardId;
    },
    reloadCard() {
      return this.$store.state.reloadDropCard;
    },
    loadingCard() {
      return this.$store.state.loadingCard;
    }
  },


  mounted () {
    this.baseUrl = conf.default.server.host;
    setTimeout(() => {
      this.handleScroll();
    }, 500);
  },


  methods: {
    openInquiry(inquiryId){
      const { href } = this.$router.resolve({ name: 'Inquiry', params: { id: inquiryId } });
      const newTab = window.open(href, '_blank'); // Open a new tab with the resolved route URL
      newTab.focus(); // Set focus on the new tab
    },
    getCollectionData(collectionId){
      restAdapter.get(`/api/dashboard/collections/${collectionId}/${this.column.serviceId}/card_details`).then(response => {
        this.thumbnail = response.data.thumbnail;
        this.superAdmin = response.data.super_admin;
        this.accountManagers = response.data.account_managers;
        this.assignedSuppliers = response.data.assigned_suppliers;
        this.buyer = response.data.buyer;
        this.brand = response.data.brand;
        this.deadline = response.data.deadline;
        this.changedDeadline = this.deadline,
        this.isEndOfMainService = response.data.lastInquirySubServiceStatus;
        this.hasParallelService = this.card.hasParallelService;
        this.status = response.data.status;
        const today = this.$moment().format("YYYY-MM-DD");
        const start = this.$moment(today, "YYYY-MM-DD");
        const end = this.$moment(response.data.deadline, "YYYY-MM-DD");
        const remainingDays = end.businessDiff(start, true);
        if(remainingDays > 0){
          this.calculatedDays = {
            ...this.calculatedDays,
            message: remainingDays + " day(s) left",
            color: "green"
          }
        } else {
          this.calculatedDays = {
            ...this.calculatedDays,
            message:  "overdue by " + Math.abs(remainingDays) + " day(s)",
            color: "red"
          }
        }
        this.dataLoaded = true;
        this.getDefaultImage();
      }).catch(error => {
        this.loaded = false;
        this.dataLoaded = false;
        this.inquiriesLoaded = false;
        this.assignessLoaded = false;
      })
    },

    showParallelService() {
      this.$emit('show-parallel-service', {cardId: this.card.id, serviceId: this.column.serviceId});
    },

    updateMainServiceStatus() {
      const collectionSubServiceId = this.card.current_sub_services.collection_sub_service_id;
      restAdapter.post(
        `/api/collections/timeline/collection_sub_service/${collectionSubServiceId}/updateStatus`,
        {
          status: this.status !== this.statusData.completed ? this.statusData.completed : this.statusData.progress,
        }
      ).then(() => {
        if(this.status !== this.statusData.completed){
          this.status = this.statusData.completed;
          notification.success("Service marked as completed");
        } else {
          this.status = this.statusData.progress;
          notification.success("Service marked as not completed");
        }
      }).catch(error=> notification.error(error.response.data.error));
    },

    handleReload() {
      if(this.reloadCard === `${this.card.id}-${this.column.serviceId}`){
        store.commit('SET_RELOAD_DROP_CARD', null);
        this.notifiedToReload();
      } else if (this.reloadCard === `${this.card.id}-${this.column.serviceId}-currentColumn`){
        store.commit('SET_RELOAD_DROP_CARD', null);
        this.dataLoaded = true;
        this.assigneesLoaded = true;
      }
    },

    notifiedToReload(){
        this.loaded = false;
        this.dataLoaded = false;
        this.assigneesLoaded = false;
        this.handleScroll();
    },

    handleLoading(){
      if(this.loadingCard === `${this.card.id}-${this.column.serviceId}`){
        this.dataLoaded = false;
        this.assigneesLoaded = false;
        store.commit('SET_LOADING_CARD', null);
      }
    },

    handleScroll () {
      const res = this.isInViewport();
      if (res === true) {
        this.display = true;
        window.removeEventListener('scroll', this.handleScroll);
      }
      if (this.display === true && !this.loaded) {
        this.loaded = true;
        setTimeout(() => {
          this.getCollectionData(this.card.id);
        }, 200 + 10 * this.index);
      }
    },

    getDefaultImage(){
      if (!this.thumbnail || !this.isSupportedFileType(this.thumbnail.upload_name)) {
        this.defaultImageUrl = this.sampleImageCollection;
        this.imageLoading = false;
      } else {
        restAdapter
          .getWithOutCache(`/api/collections/file_show/${this.thumbnail.upload_name}`)
          .then((response)  => {
            this.defaultImageUrl = response.data.file;
            this.imageLoading = false;
          })
          .catch((error) => {
            this.defaultImageUrl = this.sampleImageCollection;
            this.imageLoading = false;
          });
      }

    },

    isInViewport() {
      const element = document.getElementById(this.elementId);
      const rect = element.getBoundingClientRect();
      const html = document.documentElement;
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || html.clientHeight) &&
        rect.right <= (window.innerWidth || html.clientWidth)
      );

    },

    updateChatStatus(status) {
      this.chatStatus = status;
    },

    updateChatId(chatId) {
      this.chatID = chatId;
      return chatId;
    },

    openPopUp(){
      this.activePopUp = true;
    },

    async showChat(card) {
      this.refreshPopup();
      this.collectionId = card.id;
      this.active = true;
      this.$emit('click', this.collectionId);
    },

    closePopUp() {
      this.collectionId = null;
      this.active = false;
      this.$emit('click', this.collectionId);
    },

    refreshPopup() {
      this.refresh = false;
      this.$nextTick(() => {
        this.refresh = true;
      });
    },
    getInquiriesAndAssignees(card){
        this.getInquiries(card);
        this.getAssignees(card);
    },
    getInquiries(card){
      if(this.inquiriesLoaded){
        return;
      }
      restAdapter.get(`/api/collection/${card.id}`)
        .then(response => {
          this.inquiriesLoaded = true;
          this.inquiries = response.data.collection_data.inquiries;
        }).catch(error => this.inquiriesLoaded = false)

    },
    getAssignees(){
      if(this.assigneesLoaded){
        return;
      }
      restAdapter.get(`/api/dashboard/collection/${this.card.current_sub_services.collection_sub_service_id}`)
        .then(response => {
          this.assigneesLoaded = true;
          this.assignees = response.data.assignees;
        }).catch(error => this.assigneesLoaded = false)

    },
    changeDeadLine(card){
      const subServiceId = card.current_sub_services.sub_service_id;
      const start = this.$moment(this.deadline, "YYYY-MM-DD");
      const end = this.$moment(this.changedDeadline, "YYYY-MM-DD");
      const businessDiff = end.businessDiff(start, true);
      restAdapter.post(`/api/dashboard/collections/${card.id}/change-deadline`,
        {
          subServiceId: subServiceId,
          businessDiff: businessDiff
        }
      ).then(response => {
        this.showCalendar = false;
        notification.success(response.data.message);
        store.commit('SET_RELOAD_DROP_CARD', `${this.card.id}-${this.column.serviceId}`);
      }).catch(error => {
        this.showCalendar = false;
        this.changedDeadline = this.deadline;
        notification.error(error.response.data.error);
      })
    },
    isSupportedFileType(filename) {
      const extension =
        filename.match(/\.[0-9a-z]+$/i) && filename.match(/\.[0-9a-z]+$/i)[0];
      const supportedFileTypes = this.acceptedThumbnailTypes.split(",");
      return supportedFileTypes.includes(extension);
    },
    viewCollection(){
      this.active = false;
      this.$router.push({
          name: routes.COLLECTION,
          params: { id: this.card.id }
      });
    }
  },
}

</script>
<style scoped>
  .inquiry-card {
    margin: 10px;
  }
  .card-image {
    pointer-events: none;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .loadingImage {
    border: 1px solid gray;
    background-color: gray;
    border-radius: 15px;
    opacity: 0.2;
    height: 100%;
    width: 80%;
  }
  .loadingData {
    border: 1px solid gray;
    background-color: gray;
    border-radius: 15px;
    opacity: 0.2;
    height: 50%;
    width: 80%;
    margin: auto;
  }
  .card-header {
    padding-bottom: 0 !important;
  }
  .embed {
    width: 100%;
    height: 100%;
  }
  .centered-and-cropped {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .close-icon{
    float: right;
    border-radius: 50%;
    box-shadow: 0 0 2px #333;
    cursor: pointer;
    height: 1.5rem;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 2.5rem;
    font-size: 28px;
    color:#492a67;
  }
  .close {
    margin: 0px 18px 18px 18px;
    padding-top: 18px;
    width: auto;
    cursor: pointer;
  }

</style>
