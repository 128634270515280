<template>
  <div class="chat-container">
    <div id="collectionTimeline" style="width: 1" class="my-2">
      <Timeline
        :collectionId="collectionId"
        :accountManager="true"
        @reload-card="$emit('reload-card')"
        @reload-dashboard="$emit('reload-dashboard')"
      />
    </div>
    <v-col cols="12" id="chat">
      <chat
        :collectionId="collectionId"
        :customer="customer"
        :assignedSuppliers= "assignedSuppliers"
        :startingRoomId="startingRoomId"
        :accountManagers="accountManagers"
        :superAdmin="superAdmin"
        :activeCard="activeCard"
      />
    </v-col>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import Chat from "../components/Chat"
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import auth from "../auth";
import restAdapter from "../restAdapter";
import * as conf from "../conf.yml";
import notification from "../notification";
import utils from "../utils";
import Timeline from "../components/Inquiry/Timeline";
import routes from "../router/routes";

export default {
  name: "PopupChat",
  components: {
    Timeline,
    Chat,
  },

  props: {
    collectionId: {
      type: Number,
      required: true,
    },
    startingRoomId: {
      type: [Number, null],
    },
    assignedSuppliers: {
      type: Array,
      default: () => [],
    },
    customer: {
      type: [Object, null]
    },
    accountManagers: {
      type: Array,
      default: () => [],
    },
    superAdmin: {
      type: [Object, null]
    },
    active: {
      type: Boolean,
      default: false
    },
    activeCard: {
      type: [Number, null]
    }
  },

  methods: {
    notifyDashboard(){
      this.$emit("notify-dashboard", true);
    },
  },

  data: () => ({
    currentUserId: 0,
  }),
};
</script>

<style scoped>
#chat {
  text-align: left;
}

.chat-container {
  padding: 10px;
}

.md-button.md-theme-default.md-raised:not([disabled]).md-primary {
    color: #fff !important;
    background-color: #582963 !important;
}

.md-icon.md-theme-default.md-icon-font.md-primary {
    color: #c6a8ec !important;
    color: var(--md-theme-default-primary-on-background, #c6a8ec) !important;
}

</style>
